import React from "react"
import { StaticImage } from "gatsby-plugin-image"


export default function Sidebar() {
  return (

            <div className="bg-slate-300 rounded-lg text-center py-4 px-4 sm:py-8 sm:px-6 lg:px-8">


                    <div className="flex flex-col flex-grow  text-center py-8">
                        <h2 className="text-2xl font-extrabold text-slate sm:text-3xl">
                        <span className="block">Etude de sol Vente de terrain</span>
                        </h2>
                        <p className="mt-4 text-lg leading-6 text-slate-500">
                        La loi Elan impose la réalisation d’une étude de sol avant la vente d’un terrain constructible et à risque. La mission d’étude de sol qu’il vous faut est la G1 PGC
                        </p>
                        <p className="mt-4 leading-6 text-green-200">
                          <a href="/bureau/type-etude-sol/etude-sol-g1/">
                            <StaticImage
                              className="bg-white rounded-md"
                              src="../../images/sidebar/sidebar-etude-sol-g1-mini.jpg" 
                              alt=""
                            />
                          </a>
                        </p>
                        <p className="mt-4 text-right text-lg leading-6 text-emerald-500">
                          <a href="/bureau/type-etude-sol/etude-sol-g1/" className="underline">
                            En savoir plus
                          </a>
                        </p>
                    </div>



                    <div className="flex flex-col flex-grow  text-center py-8">
                        <h2 className="text-2xl font-extrabold text-slate sm:text-3xl">
                        <span className="block">Etude de sol Construction</span>
                        </h2>
                        <p className="mt-4 text-lg leading-6 text-slate-500">
                        Maison neuve, agrandissement, extension... Recevez un devis pour une étude de sol préalable à construction (mission G2)
                        </p>
                        <p className="mt-4 leading-6 text-green-200">
                          <a href="/bureau/type-etude-sol/etude-sol-g2/">
                            <StaticImage
                              className="bg-white rounded-md"
                              src="../../images/sidebar/sidebar-etude-sol-g2-mini.jpg" 
                              alt=""
                            />
                          </a>
                        </p>
                        <p className="mt-4 text-right text-lg leading-6 text-emerald-500">
                          <a href="/bureau/type-etude-sol/etude-sol-g2/" className="underline">
                            Cliquez-ici
                          </a>
                        </p>
                    </div>


                    <div className="flex flex-col flex-grow  text-center py-8">
                        <h2 className="text-2xl font-extrabold text-slate sm:text-3xl">
                        <span className="block">Etude de sol Après sinistre</span>
                        </h2>
                        <p className="mt-4 text-lg leading-6 text-slate-500">
                        La réalisation d’une étude de sol G5 est indispensable pour comprendre les causes géotechniques de la fissuration d’une maison et envisager le type de reprise en sous œuvre adéquat.
                        </p>
                        <p className="mt-4 leading-6 text-green-200">
                          <a href="/bureau/type-etude-sol/etude-sol-g5/">
                            <StaticImage
                              className="bg-white rounded-md"
                              src="../../images/sidebar/sidebar-etude-sol-g5-mini.jpg" 
                              alt=""
                            />
                          </a>
                        </p>
                        <p className="mt-4 text-right text-lg leading-6 text-emerald-500">
                          <a href="/bureau/type-etude-sol/etude-sol-g5/" className="underline">
                            Cliquez-ici
                          </a>
                        </p>
                    </div>
                    
            </div>



        )
    }